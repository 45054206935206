import {ReleaseTogglesType} from "./ReleaseToggles";

export const RELEASE_TOGGLE_ALL_ON: ReleaseTogglesType = {
    enableGoalsPrioritization: true,
    enableMonitorPortfolioReserveReport: true,
    enableHolisticAdvice: true,
    enableLifeInsuranceAsset: true,
    enableEquityCompensationAsset: true,
    enableCurrentNetWorth: true,
    enableReviseAssetAllocation: true,
    enableToonimo: true,
    enableProfileAssistant: true,
    enableMultipleSpendingPeriods: true,
    enableInflowReserve: true,
    enableActivePortfolioReserve: true,
    enableSendReportToDCH: true,
    enableDetailedAssetSummaryReport: true,
    enableDetailedGoalsSummaryReport: true,
    enableGoalsSummaryReport: true,
    enableWealthTransferMenu: true,
    enableFutureValueOfExcessAssets: true,
    enableDetailedCurrentVsProposedReport: true,
    enabledNetWorthOverTime: true,
    enableMeetingManagement: true,
    enableMeetingManagementRemote: true,
    enableDetailedAssetRelianceReport: true,
    enableClientDocument: true,
    enableDetailedCurrentNetWorthReport:true,
    enableTrustInflows: true,
    enableProposalApproval: true,
    enableBlockProfileEditsWithActiveProposals: true,
    enablePartiallyOwnedInvestmentAccounts: true,
    enableAssetTitling: true,
    enableFBNIEditPR:true,
    enableRiskControlEnhancements: true,
    enableGoalsFunding: true,
    enableOtherExcludeAssetsOnAssetReliance: true,
    enableSampleTab: true,
    enableHeldAwayAccountForAsset: true,
    enableMarketStress: true,
    enableUpdatesToEditAssetReliance: true,
    enableMeetingManagementClientViewPointer: true,
    enableWealthPlanOptimizer: true,
    enableInstitutionDropdown: true,
    enableFamilyTreeUpdates: true,
    enableGiftToGrantor: true,
    enableWealthPlanOptimizerReport: true,
    enableBankAccountAsset: true,
    enableCompareScenarios: true,
    enableWPOStrategyEnabledStatusChange: true

}
export const RELEASE_TOGGLE_ALL_OFF: ReleaseTogglesType = {
    enableGoalsPrioritization: false,
    enableMonitorPortfolioReserveReport: false,
    enableHolisticAdvice: false,
    enableLifeInsuranceAsset: false,
    enableEquityCompensationAsset: false,
    enableCurrentNetWorth: false,
    enableReviseAssetAllocation: false,
    enableToonimo: false,
    enableMultipleSpendingPeriods: false,
    enableProfileAssistant: false,
    enableInflowReserve: false,
    enableActivePortfolioReserve: false,
    enableSendReportToDCH: false,
    enableDetailedAssetSummaryReport: false,
    enableDetailedGoalsSummaryReport: false,
    enableWealthTransferMenu: false,
    enableFutureValueOfExcessAssets: false,
    enableDetailedCurrentVsProposedReport: false,
    enableMeetingManagement: false,
    enableMeetingManagementRemote: false,
    enableDetailedAssetRelianceReport: false,
    enableClientDocument: false,
    enableTrustInflows: false,
    enableProposalApproval: false,
    enableBlockProfileEditsWithActiveProposals: false,
    enablePartiallyOwnedInvestmentAccounts: false,
    enableAssetTitling: false,
    enableFBNIEditPR:false,
    enableRiskControlEnhancements: false,
    enableGoalsFunding: false,
    enableOtherExcludeAssetsOnAssetReliance: false,
    enableSampleTab: false,
    enableHeldAwayAccountForAsset: false,
    enableMarketStress: false,
    enableUpdatesToEditAssetReliance: false,
    enableMeetingManagementClientViewPointer: false,
    enableWealthPlanOptimizer: false,
    enableInstitutionDropdown: false,
    enableFamilyTreeUpdates: false,
    enableGiftToGrantor: false,
    enableWealthPlanOptimizerReport: false,
    enableBankAccountAsset: false,
    enableCompareScenarios: false,
    enableWPOStrategyEnabledStatusChange: false
}
