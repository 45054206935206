import {AssetsViewContextValue} from "../../common/AssetsViewContext";
import {StandaloneAccount} from "../../models/StandaloneAccount";
import {HeldAwayAccount, HeldAwayAccountSummary, LegalAgreement} from "../../models/InvestmentProgram";
import {
    PartiallyOwnedInvestmentAccountReadModel,
    PartiallyOwnedLegalAgreement
} from "../../models/PartiallyOwnedInvestmentAccount";
import {DeleteModalProps} from "../../common/AssetsView";
import {useHistory} from "react-router-dom";
import {StandaloneAccountRow} from "./StandaloneAccountRow";
import {AssetSummaryStandaloneAccountDetails} from "./AssetSummaryStandaloneAccountDetails";
import React from "react";
import {AssetSummaryLegalAgreementDetails} from "./AssetSummaryLegalAgreementDetails";
import {LegalAgreementRow} from "./LegalAgreementRow";
import DragDrop from '@nt/dds-react/core/DragDrop';
import {Icon} from "../../../components";
import {DropResult} from "react-beautiful-dnd";
import {NO_OP} from "../../../constants/common";
import {PartiallyOwnedInvestmentAccountRow} from "./PartiallyOwnedInvestmentAccountRow";
import {AssetSummaryPartiallyOwnedInvestmentAccountDetails} from "./AssetSummaryPartiallyOwnedInvestmentAccountDetails";
import {AssetSummaryHeldAwayAccountDetails} from "./AssetSummaryHeldAwayAccountDetails";
import {HeldAwayAccountRow} from "./HeldAwayAccountRow";

export type StandaloneAccountOrLegalAgreement =
    StandaloneAccount
    | LegalAgreement
    | HeldAwayAccountSummary
    | PartiallyOwnedLegalAgreement;

export type AccountTableSectionProps = {
    viewType: AssetsViewContextValue,
    accountsAndLegalAgreements: StandaloneAccountOrLegalAgreement[],
    profileId: string,
    handleDelete: (deleteModalProps: DeleteModalProps) => void,
    hideActionMenu?: boolean,
    isDraggable: boolean,
    captureAccountsOrder?: ({source, destination}: DropResult) => void
}

export function AccountTableSection({
                                        viewType,
                                        accountsAndLegalAgreements,
                                        profileId,
                                        handleDelete,
                                        hideActionMenu = false,
                                        isDraggable = false,
                                        captureAccountsOrder = NO_OP
                                    }:
                                        AccountTableSectionProps) {
    const history = useHistory();
    const renderAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const hasOutOfEstateOwnership = accountOrLegalAgreement.memberOwnerships?.filter(x=>!x.isInEstateMember)?.length > 0
            || accountOrLegalAgreement.legalEntityOwnerships?.length > 0;
        let accountToRender;
        if ((accountOrLegalAgreement as any).legalAgreementNumber) {
            if(hasOutOfEstateOwnership) {
                accountToRender = renderPartiallyOwnedInvestmentAccountRow(accountOrLegalAgreement);
            }
            else {
                accountToRender = renderLegalAgreementRow(accountOrLegalAgreement);
            }
        }
        else if ((accountOrLegalAgreement as any).financialAccountNumber) {
            accountToRender = renderHeldAwayAccountRow(accountOrLegalAgreement);
        } else {
            accountToRender = renderStandaloneAccountRow(accountOrLegalAgreement);
        }
        return accountToRender;
    }

    const renderLegalAgreementRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const legalAgreement = accountOrLegalAgreement as LegalAgreement;

        return (
            <LegalAgreementRow
                legalAgreement={legalAgreement}
                key={legalAgreement.id}
                onClickEdit={id => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditLegalAgreement/${id}`);
                }}
                onClickViewHoldings={(legalAgreementId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/LegalAgreementHoldings/${legalAgreementId}`);
                }}
                renderLegalAgreementDetails={(agreement) => {
                    return <AssetSummaryLegalAgreementDetails
                        legalAgreement={agreement}/>;
                }}
                gridClassName={"assets-grid-with-actionmenu"}
                actionsDisabled={false}
                hideActionMenu={hideActionMenu}
            />
        );
    };

    const renderHeldAwayAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const heldAwayAccount = accountOrLegalAgreement as HeldAwayAccountSummary;
        return (
            <HeldAwayAccountRow
                heldAwayAccount={heldAwayAccount}
                key={heldAwayAccount.id}
                onClickEdit={heldAwayAccountId => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditHeldAwayAccount/${heldAwayAccountId}`);
                }}
                onClickViewHoldings={(heldAwayAccountId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/HeldAwayAccountHoldings/${heldAwayAccountId}`);
                }}
                renderHeldAwayAccountDetails={(heldAway) => {
                    return <AssetSummaryHeldAwayAccountDetails
                        heldAwayAccount={heldAway}/>;
                }}
                gridClassName={"assets-grid-with-actionmenu"}
                actionsDisabled={false}
                hideActionMenu={hideActionMenu}
            />
        );
    };


    const renderStandaloneAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const account = accountOrLegalAgreement as StandaloneAccount;
        if(account.institution?.slice(0,6) == 'Other:'){
            account.otherInstitution = account.institution?.slice(7)
            account.institution = "Other"
        }
        return (
            <StandaloneAccountRow
                key={account.id}
                standaloneAccount={account}
                onClickEdit={(accountId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditStandaloneAccount/${accountId}`);
                }}
                onClickDelete={(standaloneAccount: StandaloneAccount) => {
                    handleDelete({
                        showDeleteModal: true,
                        modalTitle: "Asset",
                        assetDescription: standaloneAccount.name,
                        assetType: "standalone account",
                        assetId: standaloneAccount.id,
                    });
                }}
                onClickViewHoldings={(accountId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/StandaloneAccount/${accountId}/Holdings`);
                }}
                actionsDisabled={false}
                hideActionMenu={hideActionMenu}
                gridClassName={"assets-grid-with-actionmenu"}
                renderStandaloneAccountDetails={(standaloneAccount) => {
                    return <AssetSummaryStandaloneAccountDetails account={standaloneAccount}/>;
                }}
            />
        );
    };


    const renderPartiallyOwnedInvestmentAccountRow = (accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) => {
        const partiallyOwnedInvestmentAccount = accountOrLegalAgreement as PartiallyOwnedLegalAgreement;
        return (
            <PartiallyOwnedInvestmentAccountRow
                key={partiallyOwnedInvestmentAccount.id}
                partiallyOwnedInvestmentAccount={partiallyOwnedInvestmentAccount}
                onClickEdit={(accountId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/EditPartiallyOwnedInvestment/${accountId}`);
                }}
                onClickDelete={(account) => {
                    handleDelete({
                        showDeleteModal: true,
                        modalTitle: "Asset",
                        assetDescription: account.legalAgreementName,
                        assetType: "partially owned investment account",
                        assetId: account.id,
                        content: <div>this is custom content</div>
                    });
                }}
                onClickViewHoldings={(legalAgreementId: string) => {
                    history.push(`/Profile/${profileId}/ClientProfile/${viewType}/PartiallyOwnedInvestmentAccountHoldings/${legalAgreementId}`);
                }}
                actionsDisabled={false}
                hideActionMenu={hideActionMenu}
                gridClassName={"assets-grid-with-actionmenu"}
                renderPartiallyOwnedInvestmentAccountDetails={(account) => {
                    return <AssetSummaryPartiallyOwnedInvestmentAccountDetails legalAgreement={account}/>;
                }}
            />
        );
    };

    if (isDraggable) {
        return (
            <DragDrop
                className="draggable"
                direction="vertical"
                draggableId="accountDraggableId1"
                droppableId="accountDroppableId1"
                onDragEnd={captureAccountsOrder}
                items={
                    accountsAndLegalAgreements.map((accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) =>
                        <div className="display-flex align-items-right" tabIndex={-1} key={accountOrLegalAgreement.id}
                             data-testid="draggable-account">
                            <Icon ariaHidden className="drag__icon" name="drag_vertical"/>
                            {renderAccountRow(accountOrLegalAgreement)}
                        </div>)
                }>
            </DragDrop>
        );
    }
    return <>
        {
            accountsAndLegalAgreements.map((accountOrLegalAgreement: StandaloneAccountOrLegalAgreement) =>
                <div className="display-flex align-items-right" tabIndex={-1} key={accountOrLegalAgreement.id}
                     data-testid={"account-row"}>
                    {renderAccountRow(accountOrLegalAgreement)}
                </div>)
        }
    </>;
}