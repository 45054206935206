import {PersonalAsset} from "./PersonalAsset";
import {StandaloneAccount} from "./StandaloneAccount";
import {GeneralInflow} from "./GeneralInflow";
import {LiabilityDetails, PersonalLiabilitySummary} from "./PersonalLiability";
import {SocialSecurity} from "./SocialSecurity";
import {HeldAwayAccount, HeldAwayAccountSummary, InvestmentProgram, LegalAgreement} from "./InvestmentProgram";
import {LifeInsurance} from "./LifeInsurance";
import {MemberOwnership, OwnershipCategory} from "./Ownership";
import {EquityCompensationFormData} from "./EquityCompensation";
import {EstateValue} from "./Holding";
import {
    PartiallyOwnedLegalAgreement
} from "./PartiallyOwnedInvestmentAccount";
import {Asset} from "./Asset";
import {TaxDetailsType, TaxStatusOptions} from "./TaxDetails";

export type Accounts = {
    totalPresentValue: number,
    totalInvestableValue: number,
    totalRiskControlPresentValue: number | null,
    totalInvestableTaxValue: number | null,
    totalInvestableTaxDeferredValue: number | null,
    totalPresentTaxLiabilityForTaxableAccounts: number | null,
    totalPresentTaxLiabilityForDeferredAccounts: number | null,
    totalPresentTaxLiabilityForAllAccounts: number | null,
    totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio: number | null,
    totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio: number | null,
    totalPresentTaxLiabilityForAllAccountsFundedByPortfolio: number | null,
    taxableRatio: number,
    data: StandaloneAccount[]
}

export type AccountBase = {
    accountNumber: string,
    financialAccountPersistentId: string,
    financialAccountManagementCode: string,
    accountStatus: string,
    marketValue: number,
    asOfDate: string,
}

export type LegalAgreementBase = Asset & TaxDetailsType & {
    asOfDate: string,
    legalAgreementNumber: string,
    legalAgreementTypeCode: string,
    institution: string,
    marketValue: number,
    marketEstateValue: EstateValue,
    taxStatus: TaxStatusOptions,
    permitBeneficiary: boolean,
    entityType: string | null,
    personalLiabilities: LiabilityDetails[],
    investableValue?: number
}

export type PersonalAssets = {
    totalPresentValue: number,
    data: PersonalAsset[]
}

export type GeneralInflows = {
    totalPresentValue: number,
    data: GeneralInflow[]
}

export type SocialSecurities = {
    totalPresentValue: number,
    data: SocialSecurity[]
}
export type LifeInsuranceSummary = {
    data: LifeInsurance[]
}
export type EquityCompensationSummary = {
    totalPresentValue: number,
    totalAfterTaxUnVestedValue: number,
    totalAfterTaxVestedValue: number,
    data: EquityCompensationFormData[]
}

export type InvestmentProgramSummary = {
    name: string,
    totalMarketValue: number,
    legalAgreements: LegalAgreement[],
    heldAwayAccounts: HeldAwayAccountSummary[]
}
export type TaxLiabilitySummary = {
    totalPresentTaxLiabilityForAllAccounts: number | null,
    totalPresentTaxLiabilityForAllAccountsFundedByPortfolio: number | null,
    totalPresentTaxLiabilityForDeferredAccounts: number | null,
    totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio: number | null,
    totalPresentTaxLiabilityForTaxableAccounts: number | null,
    totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio: number | null,
}

export type AssetsSummary = {
    accounts: Accounts,
    personalAssets: PersonalAssets,
    generalInflows: GeneralInflows,
    socialSecurities: SocialSecurities,
    lifeInsurances: LifeInsuranceSummary,
    equityCompensations: EquityCompensationSummary,
    personalLiabilities: PersonalLiabilitySummary[],
    investmentProgram: InvestmentProgram | null,
    partiallyOwnedLegalAgreements: PartiallyOwnedLegalAgreement[],
    totalNetValue: number,
    totalGrossValue: number,
    // TODO: Remove once Plan Summary is updated to use totalGrossValue
    totalPresentValue: number,
    totalLiabilitiesValue: number,
    totalTaxLiabilities: TaxLiabilitySummary,
    liabilitiesValue: EstateValue
};


export type CurrentNetWorthAsset = {
    id: string,
    name: string,
    presentValue: number,
    assetType: AssetType
    ownershipCategory: OwnershipCategory,
    memberOwnerships: MemberOwnership[],
}

export type AssetTotals = {
    totalNetValue: number,
    netValue: NetValueSummary,
    netWorthValueAtDeath: NetValueSummary,
    totalGrossValue: number,
    totalInvestableValue: number,
    totalInvestableValueForRiskControlAssets: number,
    totalLiabilitiesValue: number,
    totalTaxLiabilities: TaxLiabilitySummary,
    totalPersonalAssetsPresentValue: number,
    totalGeneralInflowsPresentValue: number,
    totalSocialSecurityPresentValue: number,
};

export type NetValueSummary = {
    inEstateValue: number,
    outOfEstateValue: number,
    totalValue: number
}

export type FutureValueOfExcessAssetsDTO = {
    futureValueOfExcessAssetsWithTNDR: number,
    futureValueOfExcessAssetsWithNNDR: number,
    displayedDiscountRateWithTNDR: number,
    displayedDiscountRateWithNNDR: number,
    fveWithLifeInsuranceDeathValueAndTNDR: number,
    fveWithLifeInsuranceDeathValueAndNNDR: number,
    futureValueOfExcessAssetsByAgeWithTNDR: number[],
    futureValueOfExcessAssetsByAgeWithNNDR: number[],
    fveWithLifeInsuranceDeathValueByAgeTNDR: number[],
    fveWithLifeInsuranceDeathValueByAgeNNDR: number[]
}

export type AssetType =
    'personalAsset'
    | 'futureInflow'
    | 'liability'
    | 'standaloneAccount'
    | 'investmentProgram'
    | 'investmentProgramHAA'
    | 'partiallyOwnedInvestmentAccount'
    | 'lifeInsurance'
    | 'equityCompensation';

export const emptyNetValueSummary: NetValueSummary = {
    inEstateValue: 0,
    outOfEstateValue: 0,
    totalValue: 0
}

export const emptyTaxLiabilitySummary: TaxLiabilitySummary = {
    totalPresentTaxLiabilityForAllAccounts: 0,
    totalPresentTaxLiabilityForAllAccountsFundedByPortfolio: 0,
    totalPresentTaxLiabilityForDeferredAccounts: 0,
    totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio: 0,
    totalPresentTaxLiabilityForTaxableAccounts: 0,
    totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio: 0,
}

export const emptyAssetsSummary: AssetsSummary = {
    accounts: {
        totalPresentValue: 0,
        totalInvestableValue: 0,
        totalRiskControlPresentValue: 0,
        totalInvestableTaxValue: 0,
        totalInvestableTaxDeferredValue: 0,
        totalPresentTaxLiabilityForTaxableAccounts: 0,
        totalPresentTaxLiabilityForDeferredAccounts: 0,
        totalPresentTaxLiabilityForAllAccounts: 0,
        totalPresentTaxLiabilityForTaxableAccountsFundedByPortfolio: 0,
        totalPresentTaxLiabilityForDeferredAccountsFundedByPortfolio: 0,
        totalPresentTaxLiabilityForAllAccountsFundedByPortfolio: 0,
        taxableRatio: 0,
        data: []
    },
    personalAssets: {
        totalPresentValue: 0,
        data: []
    },
    generalInflows: {
        totalPresentValue: 0,
        data: []
    },
    investmentProgram: null,
    partiallyOwnedLegalAgreements: [],
    socialSecurities: {
        totalPresentValue: 0,
        data: []
    },
    lifeInsurances: {
        data: []
    },
    equityCompensations: {
        totalPresentValue: 0,
        totalAfterTaxUnVestedValue: 0,
        totalAfterTaxVestedValue: 0,
        data: []
    },
    personalLiabilities: [],
    totalPresentValue: 0,
    totalNetValue: 0,
    totalGrossValue: 0,
    totalLiabilitiesValue: 0,
    liabilitiesValue: {
        inEstateValue: 0,
        outOfEstateValue: 0,
        totalValue: 0
    },
    totalTaxLiabilities: emptyTaxLiabilitySummary
};

export const emptyAssetTotals: AssetTotals = {
    totalNetValue: 0,
    totalGrossValue: 0,
    netValue: emptyNetValueSummary,
    netWorthValueAtDeath: emptyNetValueSummary,
    totalInvestableValue: 0,
    totalInvestableValueForRiskControlAssets: 0,
    totalLiabilitiesValue: 0,
    totalTaxLiabilities: emptyTaxLiabilitySummary,
    totalPersonalAssetsPresentValue: 0,
    totalGeneralInflowsPresentValue: 0,
    totalSocialSecurityPresentValue: 0,
}

export const emptyFutureValueOfExcessAssets: FutureValueOfExcessAssetsDTO = {
    futureValueOfExcessAssetsWithTNDR: 0,
    futureValueOfExcessAssetsWithNNDR: 0,
    displayedDiscountRateWithTNDR: 1,
    displayedDiscountRateWithNNDR: 1,
    fveWithLifeInsuranceDeathValueAndTNDR: 0,
    fveWithLifeInsuranceDeathValueAndNNDR: 0,
    futureValueOfExcessAssetsByAgeWithTNDR: [],
    futureValueOfExcessAssetsByAgeWithNNDR: [],
    fveWithLifeInsuranceDeathValueByAgeTNDR: [],
    fveWithLifeInsuranceDeathValueByAgeNNDR: []
}
