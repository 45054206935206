import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../routes/types";
import StrategyWithTimeFrame from "./components/StrategyWithTimeFrame";
import {EstimatedImpact, GiftToGrantorTrustStrategy, StrategyTimeFrame} from "./WealthPOTypes";
import {CreateOrEditStrategyParameters} from "./WealthPOUtils";
import {wealthPOApiClient} from "./WealthPOApiClient";

interface AddEditGiftToGrantorProps {
    refreshStrategiesSummary: () => void;
}

const AddEditGiftToGrantorTrust = ({refreshStrategiesSummary}: AddEditGiftToGrantorProps) => {
    const {id, strategyId} = useParams<RouteWithIdAndStrategyId>();
    const history = useHistory();

    const getEstimatedImpact = (requestBody: StrategyTimeFrame, signal?: AbortSignal): Promise<EstimatedImpact> => {
        return wealthPOApiClient.getEstimatedImpactForGiftToGrantorTrust(id, requestBody, signal);
    }

    const handleSaveClick = (requestParameters: CreateOrEditStrategyParameters) => {
        const {description, contributionAmount, yearsUntilFlow, yearsOfGrantorStatus} = requestParameters;

        let requestBody = {
            description,
            contributionAmount,
            yearsUntilFlow,
            yearsOfGrantorStatus
        } as GiftToGrantorTrustStrategy

        if (strategyId) {
            requestBody = {
                ...requestBody,
                strategyId: strategyId,
            };

            wealthPOApiClient.editGiftToGrantorTrustStrategy(id, requestBody)
                .then(() => {
                    refreshStrategiesSummary();
                    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`)
                });
        } else {
            wealthPOApiClient.createGiftToGrantorTrustStrategy(id, requestBody)
                .then(() => {
                    refreshStrategiesSummary();
                    history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/Summary`)
                });
        }
    }

    return (
        <StrategyWithTimeFrame profileId={id}
                               strategyId={strategyId}
                               header={`${strategyId === undefined ? "Add" : "Edit"} Gift to Grantor Trust`}
                               getEstimatedImpact={getEstimatedImpact}
                               handleSaveClick={handleSaveClick}
                               isAnnualGift={false}
        />)
}

export default AddEditGiftToGrantorTrust