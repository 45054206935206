import React, {useState} from "react";
import {formatCurrency} from "../../../utils/format";
import {Button} from "../../../components";
import {NO_OP} from "../../../constants/common";
import {BankingAccountReadModel} from "../../models/BankingAccount";

export type BankAccountSearchResultsProps = {
    bankingAccounts: BankingAccountReadModel[]
};

export function BankAccountSearchResults({bankingAccounts} : BankAccountSearchResultsProps) {
    const noValue = <span className="no-value">&ndash;&ndash;</span>;
    const accountLength = bankingAccounts.length;
    const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
    const [isHeaderChecked, setIsHeaderChecked] = useState<boolean>(false);
    const [checkedItems, setCheckedItems] = useState<boolean[]>(new Array(accountLength).fill(false));

    const handleHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        setIsHeaderChecked(checked);
        setCheckedItems(new Array(accountLength).fill(checked));
        setIsButtonEnabled(checked);
    }

    const handleItemChange = (index: number) => {
        const updatedCheckedItems = [...checkedItems];
        updatedCheckedItems[index] = !updatedCheckedItems[index];
        setCheckedItems(updatedCheckedItems);

        const isAnyItemChecked = updatedCheckedItems.some((isChecked) => isChecked);
        setIsButtonEnabled(isAnyItemChecked);
        if(isHeaderChecked && !updatedCheckedItems.every((isChecked) => isChecked)){
            setIsHeaderChecked(false);
        }
    };

    return (
        <div >
            <div aria-label="Search Results" className="banking-account-table">
                <div className="banking-account-table-header banking-account-grid">
                    <span className="marginleft-md">
                        <input className={`display-inherit`}
                               aria-label={`header-select`}
                               type="checkbox"
                               checked={isHeaderChecked}
                               onChange={handleHeaderChange} />
                    </span>
                    <span>Name</span>
                    <span>Account Number</span>
                    <span>Entity Type</span>
                    <span className="textalign-right">As of Date</span>
                    <span className="textalign-right">Present Value</span>
                </div>
                {
                    bankingAccounts.map((bankingAccount, index) => (
                        <div role="row" key={bankingAccount.accountPersistantId}>
                            <div
                                aria-label={`Account ${bankingAccount.accountNumber}`}
                                className="banking-account-table-row banking-account-grid"
                            >
                                <span className="marginleft-md">
                                    <input className={`display-inherit`}
                                           aria-label={`item-select`}
                                           type="checkbox"
                                           checked={checkedItems[index]}
                                           onChange={() => handleItemChange(index)}/>
                                </span>
                                <span>{bankingAccount.accountName}</span>
                                <span>{bankingAccount.accountNumber}</span>
                                <span>{bankingAccount.entityType}</span>
                                <span className="textalign-right">{bankingAccount.valueAsOfDate}</span>
                                <span className="textalign-right">
                        {bankingAccount.eodNtBalance !== null ? `${formatCurrency(bankingAccount.eodNtBalance)}` : noValue}
                    </span>
                            </div>
                        </div>
                    ))
                }
                <div
                    aria-label={`Add Account to Profile`}
                    className="banking-account-table-row add-account-row">
                    <Button
                        className="search-button marginleft-sm"
                        icon="left"
                        iconName="add"
                        kind="primary"
                        onClick={() => NO_OP()}
                        size="medium"
                        type="button"
                        disabled={!isButtonEnabled}
                    >
                        Add Account(s) to Profile
                    </Button>
                </div>
            </div>
        </div>
    )
}